import React, { Component } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import logo from '../Header/Group 824.png';
import './MobileHeader.css'; // Подключение файла стилей
import SEO from "../SEO"; // Импортируем SEO компонент

export default class MobileHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileMenuOpen: false // Управление состоянием мобильного меню
        };
    }

    toggleMobileMenu = () => {
        this.setState((prevState) => ({
            isMobileMenuOpen: !prevState.isMobileMenuOpen
        }));
    };

    closeMobileMenu = () => {
        this.setState({ isMobileMenuOpen: false });
    };

    render() {
        const { isMobileMenuOpen } = this.state;

        return (
            <>
                <SEO 
                title="Общественный транспорт Астана - Автобусный парк №1 | Комфорт и надежность" 
                description="Автобусный парк №1 в Астане предлагает удобные и экологически чистые автобусы, широкий выбор маршрутов и аренду транспорта для частных и корпоративных нужд." 
                keywords="автобусный парк Астана, общественный транспорт Астана, аренда автобусов Астана, автобусные маршруты Астана, экологичные автобусы, городской транспорт, прокат автобусов, комфортные автобусы, транспортные услуги Астана"
                />

                <Navbar fixed="top" expand="md" style={{ backgroundColor: '#09F', height: '80px' }} variant="dark">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.toggleMobileMenu} />
                        <Navbar.Brand href="/" className="mx-auto">
                            <img
                                src={logo}
                                height={25} // Уменьшенная высота логотипа для мобильной версии
                                width="auto" // Соответствующая ширина логотипа
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                        </Navbar.Brand>
                    </Container>
                </Navbar>

                {isMobileMenuOpen && (
                    <div className="mobile-menu-overlay" onClick={this.closeMobileMenu}></div>
                )}

                <div className={`mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
                    <div className="mobile-header-top">
                        <Nav className="flex-column">
                            <Nav.Link href="/company" className="custom-nav-link" onClick={this.closeMobileMenu}>Предприятие</Nav.Link>
                            <Nav.Link href="/for-passengers" className="custom-nav-link" onClick={this.closeMobileMenu}>Пассажирам</Nav.Link>
                            <Nav.Link href="/business" className="custom-nav-link" onClick={this.closeMobileMenu}>Бизнесу</Nav.Link>
                            <Nav.Link href="/press-center" className="custom-nav-link" onClick={this.closeMobileMenu}>Пресс-служба</Nav.Link>
                            <Nav.Link href="/contacts" className="custom-nav-link" onClick={this.closeMobileMenu}>Контакты</Nav.Link>
                        </Nav>
                        <div className="language-switcher">
                            <Button variant="link" className="language-btn">ҚАЗ</Button> | <Button variant="link" className="language-btn">РУС</Button>
                        </div>
                    </div>
                    <div className="social-icons">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <img src={require('../../icon/3932196.png')} alt="Facebook" />
                        </a>
                        <a href="https://www.instagram.com/avtopark_01/?igsh=MW55cTN3Zm9hazB1NA%3D%3D" target="_blank" rel="noopener noreferrer">
                            <img src={require('../../icon/5618785.png')} alt="Instagram" />
                        </a>
                        <a href="https://www.tiktok.com/@avtopark_01_astana" target="_blank" rel="noopener noreferrer">
                            <img src={require('../../icon/3046114.png')} alt="TikTok" />
                        </a>
                    </div>
                </div>

                {/* Контент сайта для мобильной версии */}
                <div className="mobile-content">
                    
                </div>
            </>
        );
    }
}
