import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const ContactSection = () => {
  return (
    <section className="contact-section py-5" aria-labelledby="contact-heading">
      <Container>
        <Row>
          <Col>
            <h2 id="contact-heading">Свяжитесь с нами</h2>
            <p>У вас есть вопросы? Мы готовы помочь вам в любое время.</p>
            <Button variant="primary" href="/contacts">Связаться с нами</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection;
