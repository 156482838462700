import React, { Component } from 'react';
import NewsList from '../../Components/News/NewsList';
import { Helmet } from 'react-helmet';
import './PressCenter.css';

export default class PressCenter extends Component {
  render() {
    return (
      <div className="PressCenter">
        <Helmet>
          <title>Пресс-служба - Автобусный Парк №1</title>
          <meta name="description" content="Последние новости и пресс-релизы Автобусного Парка №1. Будьте в курсе событий и обновлений." />
          <link rel="canonical" href="https://yourwebsite.com/press-center" />
        </Helmet>
        <h1>Пресс-служба</h1>
        <NewsList />
      </div>
    );
  }
}
