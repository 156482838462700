// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Refrigerator Deluxe', sans-serif !important;
    src: url('/assets/fonts/RefrigeratorDeluxe.woff2') format('woff2'),
         url('/assets/fonts/RefrigeratorDeluxe.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'Refrigerator Deluxe',  sans-serif !important;
    margin-top: 30px;
    padding: 0;
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
