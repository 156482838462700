import React, { useState } from 'react';
import Slider from 'react-slick';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './VacancyCarousel.module.css'; // Подключаем стили
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

const vacancies = [
    {
        title: 'Водитель автобуса',
        description: 'Ответственность за безопасное и комфортное передвижение пассажиров по маршруту. Соблюдение графика и правил дорожного движения.',
        requirements: 'Права категории D, опыт вождения от 2 лет, знание города. Умение работать с навигационными системами и картами.',
        salary: 'Заработная плата от 500 000',
        image: 'chauffeur_8711413.png',
    },
    {
        title: 'Бухгалтер материального стола',
        description: 'Учет и контроль материальных ценностей, подготовка отчетности и документации по движению ТМЦ. Взаимодействие с поставщиками и подразделениями компании.',
        requirements: 'Высшее образование в области бухгалтерии или экономики, опыт работы от 2 лет, знание 1С и других бухгалтерских программ.',
        salary: 'Заработная плата 350 000',
        image: 'accounting_6712837.png',
    },
    {
        title: 'Топливозаправщик',
        description: 'Заправка транспортных средств топливом, контроль за состоянием топливного оборудования, соблюдение техники безопасности.',
        requirements: 'Ответственность, внимательность, знание техники безопасности при работе с горюче-смазочными материалами. Опыт работы желателен.',
        salary: 'Заработная плата 180 000',
        image: 'gas-station_2082670.png',
    },
    {
        title: 'Контролер-ревизор',
        description: 'Контроль за оплптой проезда пассажира и багажа в общественном транспорте',
        requirements: 'Средне-техническое образование, приветствуется высшее. Без опыта работы (стажировка). Начало работы 07-00ч, окончание работы 21-00ч. Обеденый перерыв общее время 2 часа. Сменный график 2/2. Развозка персонала.',
        salary: 'Заработная плата 200 000 на руки',
        image: 'Котролер-ревизор.png',
    },
];

const VacancyCarousel = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedVacancy, setSelectedVacancy] = useState(null);

    const handleShow = (vacancy) => {
        setSelectedVacancy(vacancy);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <div className={styles.carouselContainer}>
            <Slider {...settings}>
                {vacancies.map((vacancy, index) => (
                    <div key={index} className={styles.vacancyCard}>
                        <div className={styles.photoContainer}>
                            <img src={require(`../../icon/vacancies/${vacancy.image}`)} alt={vacancy.title} className={styles.vacancyPhoto} />
                        </div>
                        <div className={styles.vacancyInfo}>
                            <h4 className={styles.vacancyTitle}>{vacancy.title}</h4>
                            <p className={styles.vacancyDescription}>{vacancy.description}</p>
                            <p className={styles.vacancyRequirements}><strong>Требования:</strong> {vacancy.requirements}</p>
                            <p className={styles.vacancySalary}>{vacancy.salary}</p>
                            <Button variant="primary" onClick={() => handleShow(vacancy)}>Подать заявку</Button>
                        </div>
                    </div>
                ))}
            </Slider>

            {/* Модальное окно */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedVacancy?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedVacancy && (
                        <>
                            <div className={styles.modalPhotoContainer}>
                                <img src={require(`../../icon/vacancies/${selectedVacancy.image}`)} alt={selectedVacancy.title} className={styles.modalPhoto} />
                            </div>
                            <p>{selectedVacancy.description}</p>
                            <p><strong>Требования:</strong> {selectedVacancy.requirements}</p>
                            <Form>
                                <Form.Group controlId="formName">
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control type="text" placeholder="Введите ваше имя" />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Введите ваш email" />
                                </Form.Group>
                                <Form.Group controlId="formPhone">
                                    <Form.Label>Телефон</Form.Label>
                                    <Form.Control type="tel" placeholder="Введите ваш номер телефона" />
                                </Form.Group>
                                <Form.Group controlId="formResume">
                                    <Form.Label>Загрузить резюме</Form.Label>
                                    <Form.Control type="file" />
                                </Form.Group>
                            </Form>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="primary">
                        Отправить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.nextArrow}`}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.prevArrow}`}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

export default VacancyCarousel;
