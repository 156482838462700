import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import './BusRentalSection.css';  // Подключаем файл со стилями

const BusRentalSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBus, setSelectedBus] = useState(null);

  const buses = [
    {
      title: "Городской автобус",
      imgSrc: "https://inbusiness.kz/uploads/2023-6/UjQOhfHZ.webp",
      description: "Идеально подходит для перевозки сотрудников.",
      details: "Мы рады предложить вам городской автобус для аренды. Этот тип автобуса идеально подходит для ежедневных поездок по городу и перевозки сотрудников."
    },
    {
      title: "Туристический автобус",
      imgSrc: "https://fotobus.msk.ru/photo/29/44/11/2944112.jpg",
      description: "Отличный выбор для туристических поездок.",
      details: "Туристические автобусы идеально подходят для дальних поездок и экскурсий. Внутри автобусов созданы все условия для комфортного путешествия."
    },
    {
      title: "Микроавтобус",
      imgSrc: "https://fotobus.msk.ru/photo/29/87/43/2987432.jpg",
      description: "Компактный транспорт для малых групп.",
      details: "Микроавтобусы предоставляются для небольших групп пассажиров. Идеально подходят для корпоративных мероприятий и частных поездок."
    }
  ];

  const handleShowModal = (bus) => {
    setSelectedBus(bus);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBus(null);
  };

  return (
    <section className="bus-rental-section py-5 bg-light" aria-labelledby="bus-rental-heading">
      <Container>
        <Row>
          <Col>
            <h2 id="bus-rental-heading">Аренда Автобусов</h2>
            <p>Мы предлагаем широкий выбор автобусов для аренды, подходящих для любых нужд вашего бизнеса.</p>
          </Col>
        </Row>
        <Row>
          {buses.map((bus, index) => (
            <Col md={4} key={index}>
              <Card className="mb-4">
                <Card.Img variant="top" src={bus.imgSrc} alt={`${bus.title} для аренды`} />
                <Card.Body>
                  <Card.Title>{bus.title}</Card.Title>
                  <Card.Text>{bus.description}</Card.Text>
                  <Button variant="primary" onClick={() => handleShowModal(bus)}>Подробнее</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {selectedBus && (
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedBus.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{selectedBus.details}</p>
              <h5>Доступные модели:</h5>
              <ul>
                <li>Модель 1</li>
                <li>Модель 2</li>
                <li>Модель 3</li>
              </ul>
              <h5>Заполните форму для получения консультации:</h5>
              <Form>
                <Form.Group controlId="companyName">
                  <Form.Label>Наименование компании</Form.Label>
                  <Form.Control type="text" placeholder="Введите название вашей компании" />
                </Form.Group>
                <Form.Group controlId="contactPerson">
                  <Form.Label>Контактное лицо</Form.Label>
                  <Form.Control type="text" placeholder="Введите ваше имя" />
                </Form.Group>
                <Form.Group controlId="contactNumber">
                  <Form.Label>Контактный номер телефона</Form.Label>
                  <Form.Control type="tel" placeholder="Введите ваш номер телефона" />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Введите ваш email" />
                </Form.Group>
                <Form.Group controlId="comments">
                  <Form.Label>Комментарии</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Напишите ваш комментарий" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Закрыть
              </Button>
              <Button variant="primary">
                Отправить заявку
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    </section>
  );
};

export default BusRentalSection;
