import React from 'react';
import styles from './Leadership.module.css'; // Подключаем стили

const leaders = [
    {
        name: 'Тұқпатов Мадияр Дастанович',
        position: 'Председатель Правления',
        photo: 'leader1.jpg',
        description: 'Описание деятельности и достижений председателя компании.'
    },
    {
        name: 'Байбеков Жомарт Апсаматович',
        position: 'Первый Заместитель Председателя Правления',
        photo: 'leader4.jpg',
        description: 'Описание деятельности и достижений заместителя председателя.'
    },
    {
        name: 'Тулеубергенов Бауржан Туякович',
        position: 'Главный инженер',
        photo: 'leader2.jpg',
        description: 'Описание деятельности и достижений главного инженера компании.'
    },
    {
        name: 'Молдакаликов Бахитбек Сабитбекович',
        position: 'Заместитель председателя правления по экономике и финансам',
        photo: 'leader3.jpg',
        description: 'Описание деятельности и достижений заместителя председателя.'
    },
    {
        name: 'Булатов Сабит Туреевич',
        position: 'Заместитель председателя правления по эксплуатации',
        photo: 'leader5.jpg',
        description: 'Описание деятельности и достижений заместителя председателя.'
    },
    {
        name: 'Асанов Еркин Кажманович',
        position: 'Заместитель председателя правления по организационно-юридическим вопросам',
        photo: 'leader6.jpg',
        description: 'Описание деятельности и достижений заместителя председателя.'
    }
];

const Leadership = () => {
    return (
        <div className={styles.leadershipContainer}>
            {leaders.map((leader, index) => (
                <div key={index} className={styles.leaderCard}>
                    <div className={styles.photoContainer}>
                        <img src={require(`../../assets/leaders/${leader.photo}`)} alt={leader.name} className={styles.leaderPhoto} />
                    </div>
                    <div className={styles.leaderInfo}>
                        <h4 className={styles.leaderName}>{leader.name}</h4>
                        <h5 className={styles.leaderPosition}>{leader.position}</h5>
                        <p className={styles.leaderDescription}>{leader.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Leadership;
