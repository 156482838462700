import React from 'react';
import diagramImage from '../../assets/Диаграмма структура компаний .jpg'; // Убедитесь, что файл находится в правильной директории
import styles from './CompanyStructure.module.css';

const CompanyStructure = () => {
    return (
        <div className={styles.structureContainer}>
            <h2>Структура компании</h2>
            <img 
                src={diagramImage}
                alt="Структура компании" 
                width={990} 
                height={1234} 
                className={styles.companyStructureImage} // если нужно добавить стили для изображения
            />
        </div>
    );
};

export default CompanyStructure;

