import React from 'react';
import styles from './History.module.css'; // Подключаем стили для компонента

const History = () => {
    return (
        <div>
            <h2 className={styles.heading}>ИСТОРИЧЕСКАЯ ХРОНОЛОГИЯ</h2>
            <div className={styles.timeline}>
                <div className={styles.timelineItem}>
                    <div className={styles.dot}></div>
                    <div className={styles.content}>
                        <h4 className={styles.title}>1 июля 1954 года</h4>
                        <p className={styles.description}>
                           Оброзование автобазы №2 Акмолинского Областного управления автомобильного транспорта и шоссейных дорог.
                        </p>
                    </div>
                </div>

                <div className={styles.timelineItem}>
                    <div className={styles.dot}></div>
                    <div className={styles.content}>
                        <h4 className={styles.title}>4 мая 1961 года</h4>
                        <p className={styles.description}>
                            Автобаза №2 Акмолинского Областного управления автомобильного транспорта и шоссейных дорог была переименована в Целиноградскую автобазу №1
                        </p>
                    </div>
                </div>
                <div className={styles.timelineItem}>
                    <div className={styles.dot}></div>
                    <div className={styles.content}>
                        <h4 className={styles.title}>15 марта 1962 года</h4>
                        <p className={styles.description}>
                            Целиноградская автобаза №1 была переименована в "Целиноградский автобусный парк №1" Целинного края.
                        </p>
                    </div>
                </div>
                <div className={styles.timelineItem}>
                    <div className={styles.dot}></div>
                    <div className={styles.content}>
                        <h4 className={styles.title}>Сентябрь 1974 года</h4>
                        <p className={styles.description}>
                            Предприятие было передислоцировано на нынешнюю территорию: ул. Пушкина 37/2
                        </p>
                    </div>
                </div>
                <div className={styles.timelineItem}>
                    <div className={styles.dot}></div>
                    <div className={styles.content}>
                        <h4 className={styles.title}>12 января 1999 года</h4>
                        <p className={styles.description}>
                            "Целиноградский автобусный парк №1" был переименован в ОАО "Автобусный парк №1"
                        </p>
                    </div>
                </div>
                <div className={styles.timelineItem}>
                    <div className={styles.dot}></div>
                    <div className={styles.content}>
                        <h4 className={styles.title}>09 декабря 2004 года</h4>
                        <p className={styles.description}>
                            ОАО "Автобусный парк №1" был переименован в АО "Автобусный парк №1"
                        </p>
                    </div>
                </div>

                {/* Добавьте больше элементов хронологии */}
            </div>
        </div>
    );
};

export default History;
