// InfoBlock.js
import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

const InfoBlock = ({ icon, title, description, onClick }) => (
  <Row className="d-flex align-items-center m-5 mb-5 custom-row" onClick={onClick} style={{ cursor: 'pointer' }}>
    <Col md="3">
      <Image src={icon} width={150} height={150} className="mr-3 image-icon" rounded />
    </Col>
    <Col md="9">
      <h5>{title}</h5>
      <p>{description}</p>
    </Col>
  </Row>
);

export default InfoBlock;
