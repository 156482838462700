import React, { useState } from 'react';
import { Card, Carousel, Modal, Button } from 'react-bootstrap';

const GalleryCard = ({ images, title, description, date }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Разделяем дату на день и месяц
    const [day, month] = date.split(' ');

    return (
        <>
            <Card className="custom-card" onClick={handleShow}>
                <div className="date-overlay">
                    <span className="day">{day}</span>
                    <span className="month">{month}</span>
                </div>
                <Carousel>
                    {images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img className="d-block w-100" src={image} alt={`Slide ${index + 1}`} />
                        </Carousel.Item>
                    ))}
                </Carousel>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{description}</Card.Text>
                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel>
                        {images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img className="d-block w-100" src={image} alt={`Slide {index + 1}`} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GalleryCard;
