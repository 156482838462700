import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GalleryCard from '../../Components/GalleryCard';
import './NewsSection.css'; // Подключение файла стилей
import image1 from '../../assets/5308051542147980450.jpg';
import image2 from '../../assets/5308051542147980451.jpg';
import image3 from '../../assets/5308051542147980452.jpg';

const NewsSection = () => {
    const images1 = [image1, image2, image3];
    const images2 = [image2, image1, image3];
    const images3 = [image3, image1, image2];

    return (
        <Container className="news-section-container">
            <h2 className='text-center m-4'>Новости и объявления</h2>
            <Row className="card-group-custom justify-content-center m-4">
                <Col md={4} sm={6} xs={12} className="d-flex justify-content-center mb-4">
                    <GalleryCard 
                        images={images1} 
                        title="Фотогалерея" 
                        description="Посмотрите нашу фотогалерею новых автобусов и обновленного автопарка." 
                        date="07 августа"  // Добавляем дату
                    />
                </Col>
                <Col md={4} sm={6} xs={12} className="d-flex justify-content-center mb-4">
                    <GalleryCard 
                        images={images2} 
                        title="Расширение маршрутов" 
                        description="Новые маршруты для вашего удобства теперь доступны в нашем автобусном парке." 
                        date="12 августа"  // Добавляем дату
                    />
                </Col>
                <Col md={4} sm={6} xs={12} className="d-flex justify-content-center mb-4">
                    <GalleryCard 
                        images={images3} 
                        title="Мобильное приложение" 
                        description="Скачайте наше новое мобильное приложение и следите за движением автобусов в реальном времени." 
                        date="15 августа"  // Добавляем дату
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default NewsSection;
