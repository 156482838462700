import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './LostAndFoundInfo.css';  // Подключаем стили
// import styled from 'styled-components';

const LostAndFoundInfo = () => {
  return (
    <Container className="lost-and-found-info">
      <h2>Что делать, если вы забыли вещь в салоне автобуса?</h2>
      <Row className="align-items-center mt-4">
        <Col md={6}>
          <Image 
            src="https://sun9-21.userapi.com/s/v1/ig2/QzZKHgq9YusgdnYUHpgWfo0hkkwnikmneXZvN-q0oV4y19pHckPDV3SRfEKiXlmTkPSRUYXmpYP5G9c3yEIqrfga.jpg?quality=95&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280,1440x1440,1988x1988&from=bu&u=CFhufoijrExQoTnaPlvS1j3JC3TCjgvxA6Ta7rL2idc&cs=807x807" 
            rounded 
            fluid 
            alt="Фото о забытых вещах в автобусе"
            className="info-image"
          />
        </Col>
        <Col md={6} className='content-text-info'>
          <p>
            Одним из часто задаваемых вопросов пассажиров является вопрос о потерянных вещах в салоне автобуса. Мы ответим на вопросы: 
            «Как найти потерянную вещь?», «Куда обращаться в случае потери?», «Куда звонить?».
          </p>
          <p>
            Уважаемые пассажиры, хотим обратить ваше внимание на то, что на автобусной линии работает только один человек — водитель автобуса. 
            Он не может нести ответственность за оставленные в салоне вещи. Водитель проверяет салон только в конце маршрута или при сдаче автобуса на базе автопарка.
          </p>
          <p>
            Все найденные в салоне вещи мы обязательно отправляем в «Бюро находок», расположенное по указанному адресу. 
            Вы можете получить свою вещь, предъявив удостоверение личности.
          </p>
          <p>
            ☎️ Диспетчерская служба: 8 7172 54 16 45<br/>
            📍Наш адрес: ул. А. Пушкина, 37/2
          </p>
          <p>
            Обращаем ваше внимание, что наш автопарк не предоставляет видеоматериалы с камер наблюдения.
          </p>
          <p>
            🚍 🚏Во время поездок рекомендуем внимательно следить за своими вещами. Желаем вам приятных поездок!
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default LostAndFoundInfo;
