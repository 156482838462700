import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PartnersContainer = styled(Container)`
  text-align: center;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 40px;
`;

const Title = styled.h2`
  font-famaly: 'Refrigerator deluxe', sans-serif;
  font-size: 3.0rem;
  color: #000;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #777;
  margin-bottom: 40px;
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Partners = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/company?tab=partners');
  };
  

  const companies = [
    { id: 1, name: 'Forte', logo: require('../../icon/fortebank_logotype_PNG_de167fbcfe.png') },
    { id: 2, name: 'CTS', logo: require('../../icon/CTS.jpg') },
    { id: 3, name: 'EBRR', logo: require('../../icon/EBRR.jpg') },
    { id: 4, name: 'FRP', logo: require('../../icon/FRP.jpg') },
    { id: 5, name: 'Halyk Bank', logo: require('../../icon/Halyk Bank.jpg') },
    { id: 6, name: 'Iveco', logo: require('../../icon/Iveco.jpg') },
    { id: 7, name: 'Yutong', logo: require('../../icon/Yutong.png') },
    { id: 8, name: 'Avtobys', logo: require('../../icon/avtobys.jpg') },
    { id: 9, name: 'Saryarka', logo: require('../../icon/Сарыарка.jpg') },
    { id: 10, name: 'Saryarka', logo: require('../../icon/QazTechna.jpg') },
    // Добавьте остальные компании
  ];

  return (
    <PartnersContainer>
      <Title>Наши надежные партнеры</Title>
      <Description>
        Мы сотрудничаем с лучшими партнёрами в своей области, чтобы предоставить нашим клиентам наивысшее качество услуг. Наша компания активно взаимодействует с ведущими организациями страны.
      </Description>
      <LogosContainer>
        {companies.map((company) => (
          <Logo
            key={company.id}
            src={company.logo}
            alt={company.name}
            onClick={() => handleLogoClick(company.id)}
          />
        ))}
      </LogosContainer>
    </PartnersContainer>
  );
};

export default Partners;
