import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './PartnersSection.css';  // Подключаем файл со стилями

const PartnersSection = () => {
  return (
    <>
      <Helmet>
        <title>Наши Партнеры - Автобусный Парк №1</title>
        <meta name="description" content="Сотрудничайте с ведущими компаниями, которые являются нашими партнерами. Присоединяйтесь к числу успешных партнеров Автобусного Парка №1." />
        <link rel="canonical" href="https://вашсайт.kz/business/partners" />
      </Helmet>

      <section className="partners-section py-5 bg-light" aria-labelledby="partners-heading">
        <Container>
          <Row>
            <Col>
              <h2 id="partners-heading">Наши Партнеры</h2>
              <p>Мы сотрудничаем с ведущими компаниями. Присоединяйтесь к числу наших успешных партнеров.</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <img src={require('../../icon/Nomad game.png')} alt="V Всемирные игры кочевников" className="img-fluid mb-4" />
            </Col>
            <Col md={4}>
              <img src={require('../../icon/Barys.png')} alt="Barys" className="img-fluid mb-4" />
            </Col>
            <Col md={4}>
              <img src={require('../../icon/Airport.png')} alt="Airport" className="img-fluid mb-4" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PartnersSection;
