import React, { useState, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';

const LostAndFoundModal = ({ show, handleClose }) => {
  const [itemName, setItemName] = useState('');
  const [routeNumber, setRouteNumber] = useState('');
  const [paymentProof, setPaymentProof] = useState(null);
  const [itemDescription, setItemDescription] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  // Функция для проверки корректности введенного номера телефона
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[+]?[0-9]{10,15}$/; // Простой regex для проверки телефона
    return phoneRegex.test(number);
  };

  // Функция для проверки валидности формы с использованием useCallback
  const validateForm = useCallback(() => {
    if (
      itemName.trim() !== '' &&
      routeNumber.trim() !== '' &&
      paymentProof !== null &&
      itemDescription.trim() !== '' &&
      validatePhoneNumber(contactInfo)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [itemName, routeNumber, paymentProof, itemDescription, contactInfo]);

  // Обработчики изменений
  const handleItemNameChange = (e) => setItemName(e.target.value);
  const handleRouteNumberChange = (e) => setRouteNumber(e.target.value);
  const handlePaymentProofChange = (e) => setPaymentProof(e.target.files[0]);
  const handleItemDescriptionChange = (e) => setItemDescription(e.target.value);
  const handleContactInfoChange = (e) => setContactInfo(e.target.value);

  // Запускаем проверку формы каждый раз при изменении полей
  React.useEffect(() => {
    validateForm();
  }, [itemName, routeNumber, paymentProof, itemDescription, contactInfo, validateForm]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Потерянные и найденные вещи</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label htmlFor="itemName" className="form-label">Название потерянной вещи</label>
            <input type="text" className="form-control" id="itemName" placeholder="Введите название вещи" value={itemName} onChange={handleItemNameChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="routeNumber" className="form-label">Укажите номер маршрута</label>
            <input type="text" className="form-control" id="routeNumber" placeholder="Введите маршрут" value={routeNumber} onChange={handleRouteNumberChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="paymentProof" className="form-label">Предоставьте форму оплаты</label>
            <input type="file" className="form-control" id="paymentProof" accept="image/*,application/pdf" onChange={handlePaymentProofChange} />
            <small className="form-text text-muted">Вы можете загрузить изображение или PDF файл.</small>
          </div>
          <div className="mb-3">
            <label htmlFor="itemDescription" className="form-label">Описание</label>
            <textarea className="form-control" id="itemDescription" rows="3" placeholder="Опишите потерянную вещь" value={itemDescription} onChange={handleItemDescriptionChange}></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="contactInfo" className="form-label">Контактная информация</label>
            <input type="text" className="form-control" id="contactInfo" placeholder="Ваши контактные данные" value={contactInfo} onChange={handleContactInfoChange} />
            {!validatePhoneNumber(contactInfo) && contactInfo !== '' && (
              <small className="form-text text-danger">Введите корректный номер телефона.</small>
            )}
          </div>
          <Button variant="primary" type="submit" disabled={!isFormValid}>Отправить</Button>
        </form>
        <hr />
        <p><a href="/found-items">Как обратиться за помощью?</a></p> {/* Ссылка на просмотр найденных вещей */}
      </Modal.Body>
    </Modal>
  );
};

export default LostAndFoundModal;
