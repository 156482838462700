import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import forestImg from '../../assets/3094035.jpg';
import appImg from '../../assets/app.jpg';
import smartImg from '../../assets/Умный_автобус.jpg';
import './CarouselBox.css'; // Подключаем CSS файл с вашими стилями

export default class CarouselBox extends Component {
  render() {
    return (
      <div className="carouselbox-container">
        <Carousel>
          <Carousel.Item>
            <img
              className='d-block carouselbox-img'
              src={forestImg}
              alt="Forest Slide"
            />
            <Carousel.Caption className="carouselbox-caption-custom">
              <h3>Двигаемся в будущее с заботой о природе</h3>
              <p>
                Наш автопарк оснащен современными экологически
                чистыми автобусами, которые минимизируют выбросы
                и поддерживают чистоту воздуха в городе.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item >
            <img
              className='d-block carouselbox-img'
              src={smartImg}
              alt="Smart Bus Slide"
            />
            <Carousel.Caption className="carouselbox-caption-custom">
              <h3>Ваша безопасность — наш приоритет</h3>
              <p>
                Мы гарантируем безопасность на каждом маршруте, благодаря строгим стандартам обслуживания и модернизированным системам безопасности.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block carouselbox-img'
              src={appImg}
              alt="App Slide"
            />
            <Carousel.Caption className="carouselbox-caption-custom">
              <h3>Быстрое и удобное передвижение по городу</h3>
              <p>
                Скачайте мобильное приложение Avtobys, чтобы получить доступ к актуальным расписаниям, купить билеты и следить за движением автобусов в реальном времени.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    )
  }
}
