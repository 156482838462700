// DesktopHeader.js
import React, { Component } from "react";
import { Button, Container, Form, FormControl, Nav, Navbar } from "react-bootstrap";
import logo from '../Header/Group 824.png';
import './DesktopHeader.css'; // Подключение файла стилей
import SEO from "../SEO"; // Импортируем SEO компонент

export default class DesktopHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoSize: 80, // Начальный размер логотипа
            showHeaderTop: true, // Управление видимостью верхней части хедера
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 50) { // Если скролл больше 50 пикселей
            this.setState({ 
                logoSize: 50, // Уменьшаем логотип
                showHeaderTop: false // Скрываем верхнюю часть хедера
            });
        } else {
            this.setState({ 
                logoSize: 80, // Восстанавливаем начальный размер логотипа
                showHeaderTop: true // Показываем верхнюю часть хедера
            });
        }
    };

    render() {
        const { logoSize, showHeaderTop } = this.state;

        return (
            <>
                {/* SEO Optimization */}
                <SEO 
                title="Общественный транспорт Астана - Автобусный парк №1 | Комфорт и надежность" 
                description="Автобусный парк №1 в Астане предлагает удобные и экологически чистые автобусы, широкий выбор маршрутов и аренду транспорта для частных и корпоративных нужд." 
                keywords="автобусный парк Астана, общественный транспорт Астана, аренда автобусов Астана, автобусные маршруты Астана, экологичные автобусы, городской транспорт, прокат автобусов, комфортные автобусы, транспортные услуги Астана"
                />


                <Navbar fixed="top" collapseOnSelect expand="md" style={{ backgroundColor: '#09F' }} variant="dark" className={`desktop-header ${!showHeaderTop ? 'scrolled' : ''}`}>
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src={logo}
                                height={logoSize} // Динамическая высота логотипа
                                width={logoSize * 2.5} // Динамическая ширина логотипа (соотношение 2.5)
                                className={`d-inline-block align-top ${!showHeaderTop ? 'scrolled-logo' : ''}`}
                                alt="Logo"
                            />
                        </Navbar.Brand>
                        
                        <div className={`header-top w-100 ${!showHeaderTop ? 'hidden' : ''}`}>
                            {showHeaderTop && (
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="social-icons">
                                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                            <img src={require('../../icon/3932196.png')} alt="Facebook" />
                                        </a>
                                        <a href="https://www.instagram.com/avtopark_01/?igsh=MW55cTN3Zm9hazB1NA%3D%3D" target="_blank" rel="noopener noreferrer">
                                            <img src={require('../../icon/5618785.png')} alt="Instagram" />
                                        </a>
                                        <a href="https://www.tiktok.com/@avtopark_01_astana" target="_blank" rel="noopener noreferrer">
                                            <img src={require('../../icon/3046114.png')} alt="TikTok" />
                                        </a>
                                    </div>
                                    <div className="header-contact">
                                        <a href="tel:+77172541626" className="phone-link">
                                            <span className="phone-number">
                                                <img src={require('../../icon/1509974.png')} alt="phone" /> 
                                                +7 (7172) 54 16 26
                                            </span>
                                        </a>
                                    </div>
                                    <div className="language-switcher">
                                        <Button variant="link" className="language-btn">ҚАЗ</Button> | <Button variant="link" className="language-btn">РУС</Button>
                                    </div>
                                </div>
                            )}

                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto w-100">
                                    <Nav.Link href="/company" className="custom-nav-link">Предприятие</Nav.Link>
                                    <Nav.Link href="/for-passengers" className="custom-nav-link">Пассажирам</Nav.Link>
                                    <Nav.Link href="/business" className="custom-nav-link">Бизнесу</Nav.Link>
                                    <Nav.Link href="/press-center" className="custom-nav-link">Пресс-служба</Nav.Link>
                                    <Nav.Link href="/contacts" className="custom-nav-link">Контакты</Nav.Link>
                                </Nav>
                                <Form className="ml-auto d-flex">
                                    <FormControl
                                        type="text"
                                        placeholder="Поиск"
                                        className="mr-2 custom-font"
                                    />
                                    <Button variant="outline-info" className="custom-font-button" style={{ marginLeft: "10px" }}>Поиск</Button>
                                </Form>
                            </Navbar.Collapse>
                        </div>
                    </Container>
                </Navbar>
                
                {/* Контент сайта */}
                <div className="desktop-content">
                    {/* Весь ваш контент */}
                </div>
            </>
        );
    }
}
