import React from 'react';
import CarouselBox from '../../Components/CarouselBox/CarouselBox';
import NewsSection from '../../Components/NewsSection/NewsSection';
import './Home.css'; // Подключение файла стилей
import FleetSection from '../../Components/FleetSection/FleetSection';
import sampleImage from '../../assets/3590.jpg'
import FullWidthImage from '../../Components/FullWidthImage/FullWidthImage';
import ParkOverview from '../../Components/ParkOverview/ParkOverview';
import AboutVideoSection from '../../Components/AboutVideoSection/AboutVideoSection';
import Partners from '../../Components/Partners/Partners';
import SEO from '../../Components/SEO'; 

const Home = () => {
    return (
        <div>
             <SEO 
                title="АО Автобусный парк №1 - Главная" 
                description="Автобусный парк №1 в Астане. Узнайте последние новости, информацию об услугах и транспортных маршрутах." 
                keywords="Автобусный парк, Общественный транспорт, Транспортные услуги, Автобусы Астана, Маршруты автобусов, Расписание автобусов, Городские автобусы, Экологичные автобусы, Обновление автопарка, Безопасность на транспорте, Пассажирские перевозки, Комфортные автобусы, Автобусные маршруты, Автобусы на газе, Умный транспорт, Цифровизация автопарка, Современные автобусы, Транспортная инфраструктура, Инновационные решения на транспорте, Зеленый транспорт"
            />
            <CarouselBox />
            <NewsSection />
            <FleetSection/>
            <ParkOverview/>
            <FullWidthImage imageSrc={sampleImage}/>
            <AboutVideoSection/>
            <Partners/>
        </div>
    );
}

export default Home;
