import React from 'react';
import styles from './ComplianceOfficer.module.css'; // если хотите добавить стили

const ComplianceOfficer = () => {
    return (
        <div className={styles.complianceContainer}>
            <h2 className={styles.heading}>Комплаенс-офицер</h2>
            
            <p><strong>Калимуллин Дулат Темиртасович</strong></p>
            <p>тел. 8 7172 644 082 (вн. 540)</p>
            <p>моб. 8 777 772 22 32</p>
            <p>эл.адрес: <a href="mailto:d.temirtasovich@avtopark1.kz">d.temirtasovich@avtopark1.kz</a></p>

            <h3 className={styles.subheading}>Цель деятельности Комплаенс-офицера</h3>
            <p>
                Обеспечение соблюдения законодательства о противодействии коррупции, мониторинг за реализацией мероприятий по 
                противодействию коррупции, формирование внутренней правовой и корпоративной культуры Общества, отвергающей 
                коррупцию и обеспечивающей принципы честности и неподкупности при исполнении своих должностных обязанностей.
            </p>

            <h3 className={styles.subheading}>Задачи</h3>
            <ul>
                <li className={styles.listItem}>Проведение единой политики в области противодействия коррупции;</li>
                <li className={styles.listItem}>Формирование у должностных лиц и работников понимания нулевой терпимости к коррупции;</li>
                <li className={styles.listItem}>Минимизация риска вовлечения работников и третьих лиц в коррупционную деятельность;</li>
                <li className={styles.listItem}>Разработка и внедрение антикоррупционных стандартов;</li>
                <li className={styles.listItem}>Воспитание строгого соблюдения антикоррупционного законодательства;</li>
                <li className={styles.listItem}>Недопущение коррупциогенных факторов в нормативных документах;</li>
                <li className={styles.listItem}>Обеспечение открытости и объективности при выполнении работ.</li>
            </ul>


            <h3 className={styles.subheading}>Меры по предупреждению и противодействию коррупции</h3>
            <p>Направления деятельности, где возможно возникновение коррупционного риска:</p>
            <ol>
                <li className={styles.listItem}>Подарки и представительские расходы;</li>
                <li className={styles.listItem}>Привлечение третьих лиц в вопросах противодействия коррупции;</li>
                <li className={styles.listItem}>Платежи лицам, занимающим государственные должности;</li>
                <li className={styles.listItem}>Оказание спонсорской и благотворительной помощи;</li>
                <li className={styles.listItem}>Совершение сделок и проведение госзакупок;</li>
                <li className={styles.listItem}>Управление персоналом;</li>
                <li className={styles.listItem}>Финансовая и юридическая экспертиза документов.</li>
            </ol>

            <h3 className={styles.subheading}>Документы:</h3>
            <ul className={styles.list}>
                <li><a href="/documents/АО Автобусный парк №1 Перечень должностей подверженных коррупционым рискам.PDF" target="_blank">АО Автобусный парк №1 Перечень должностей подверженных коррупционым рискам</a></li>
                <li><a href="/documents/Положение антикоррупции.docx" download>Положение антикоррупции</a></li>
                <li><a href="/documents/Правила урегул. конфл.docx" download>Правила урегулирования конфликта</a></li>
                <li><a href="/documents/Инструкция по противодейстию коррупции.PDF" download>Инструкция по противодейстию коррупции</a></li>
                <li><a href="/documents/Политика противодействии коррупции.PDF" download>Политика противодействии коррупции</a></li>
            </ul>
        </div>
    );
};

export default ComplianceOfficer;
