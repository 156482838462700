import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import newsData from '../../data/newsData.json';
import './NewsList.css';

const NewsList = () => {
  return (
    <>
      <Helmet>
        <title>Новости | Автобусный парк №1</title>
        <meta name="description" content="Последние новости и события от Автобусного парка №1. Будьте в курсе всех обновлений." />
        <link rel="canonical" href="https://www.example.com/news" />
      </Helmet>
      
      <Container className="news-list">
        {newsData.map((news) => (
          <Row key={news.id} className="news-item mb-4">
            <Col md={4}>
              <img src={news.imageUrl} alt={news.title} className="img-fluid" />
            </Col>
            <Col md={8}>
              <h3>{news.title}</h3>
              <p>{news.description}</p>
              <Button variant="primary" as={Link} to={`/news/${news.id}`}>
                Подробнее
              </Button>
            </Col>
          </Row>
        ))}
      </Container>
    </>
  );
};

export default NewsList;
