import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import './FleetSection.css';
import bus1 from '../../assets/IVECO Crossway.jpg'; // пример путей к изображениям
import bus2 from '../../assets/Астана, Irisbus Citelis 18M.jpg';
import bus3 from '../../assets/Астана, Yutong ZK6128HG.jpg';
import bus4 from '../../assets/Астана, Yutong ZK6180BEVG.jpg';

const FleetSection = () => {
  const buses = [
    {
      image: bus1,
      title: "Iveco Crossway Le City",
      description: "Современный городской автобус с низким уровнем выбросов и высоким уровнем комфорта.",
      year: "Закуплен в 2017 году"
    },
    {
      image: bus2,
      title: "Iveco Citelis Irisbus",
      description: "Надежный автобус для городских маршрутов с высокой топливной эффективностью.",
      year: "Закуплен в 2014 году"
    },
    {
      image: bus3,
      title: " Yutong ZK6128HG",
      description: "Автобус с просторным салоном и улучшенной маневренностью для городских улиц.",
      year: "Закуплен в 2023 году"
    },
    {
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVCqk9UC4jTqO8DyBDv03KaxSqYii7cJwqnQ&s",
      title: "Yutong ZK6180BEVG",
      description: "Автобус с просторным салоном и улучшенной маневренностью для городских улиц.",
      year: "Закуплен в 2024 году"
    }
  ];

  return (
    <Container className="fleet-section">
      <h2 className='text-center m-4'>Подвижной состав</h2>
      <Row className="justify-content-center">
        {buses.map((bus, index) => (
          <Col md={3} sm={6} xs={12} className="d-flex justify-content-center mb-4" key={index}>
            <Card className="fleet-card">
              <Card.Img variant="top" src={bus.image} />
              <Card.Body>
                <Card.Title>{bus.title}</Card.Title>
                <Card.Text>{bus.description}</Card.Text>
                <p className="year">{bus.year}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default FleetSection;
