import React from 'react';
import './FullWidthImage.css'; // Подключаем файл стилей

const FullWidthImage = ({ imageSrc }) => {
  return (
    <div className="full-width-image-container">
      <img src={imageSrc} alt="Full Width" className="full-width-image" />
    </div>
  );
};

export default FullWidthImage;
