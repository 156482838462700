import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import newsData from '../../data/newsData.json';
import './NewsDetail.css';

const NewsDetail = () => {
  const { id } = useParams();
  const news = newsData.find((news) => news.id === parseInt(id));

  return (
    <Container className="news-detail">
      {news ? (
        <>
          <h2 className="news-title">{news.title}</h2>
          <Row>
            <Col md={6}>
              <Carousel className="news-carousel">
                {news.gallery.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100 rounded"
                      src={image}
                      alt={`Slide ${index + 1}`}
                      style={{ height: '600px', width: '360px', objectFit: 'cover' }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
            <Col md={6}>
              {news.content.split('🌟').map((paragraph, index) => (
                <p key={index} className="news-content">
                  {index === 0 ? paragraph : '🌟' + paragraph}
                </p>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        <p>Новость не найдена</p>
      )}
    </Container>
  );
};

export default NewsDetail;
