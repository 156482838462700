import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './IntroSection.css';  // Подключаем файл со стилями

const IntroSection = () => {
  return (
    <section className="intro-section py-5" aria-labelledby="intro-heading">
      <Container>
        <Row>
          <Col>
            <h1 id="intro-heading">Сотрудничество с Автобусным Парком №1</h1>
            <p>
              Добро пожаловать на страницу "Бизнесу". Мы предлагаем аренду автобусов и возможности для размещения рекламы на транспорте. 
              С нами вы получите надежного партнера для вашего бизнеса.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IntroSection;
