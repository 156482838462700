import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import backgroundImage from '../../assets/Contact.jpg'; // Импорт изображения

export default class Contacts extends Component {
    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
        script.async = true;
        script.onload = () => {
            window.ymaps.ready(() => {
                const map = new window.ymaps.Map("map", {
                    center: [51.170059, 71.477234], // Координаты центра карты (Астана, ул. Александра Пушкина, 37/1)
                    zoom: 16
                });

                const placemark = new window.ymaps.Placemark([51.170059, 71.477234], {
                    hintContent: 'АО «Автобусный парк №1»',
                    balloonContent: 'г. Астана, ул. Александра Пушкина, 37/1'
                });

                map.geoObjects.add(placemark);
            });
        };
        document.body.appendChild(script);
    }

    render() {
        return (
            <div 
                style={{ 
                    marginTop: '0px', 
                    backgroundImage: `url(${backgroundImage})`, // Фоновое изображение
                    backgroundSize: 'cover', // Изображение на всю ширину
                    backgroundPosition: 'center', // Центрирование изображения
                    backgroundRepeat: 'no-repeat', // Без повторения
                    width: '100%', // Полная ширина экрана
                    minHeight: '100vh', // Минимальная высота - весь экран
                    padding: '50px 0', // Отступы сверху и снизу
                    fontFamily: 'Refrigerator Deluxe, sans-serif', // Глобальный шрифт для всего содержимого
                    fontSize: '18px', // Базовый размер шрифта
                    color: '#333', // Цвет текста
                    lineHeight: '1.6', // Интерлиньяж для улучшения читаемости
                }}
            >
                <Container>
                    <Row>
                        <Col>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>Главная</a></li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ color: '#007bff' }}>Контакты</li>
                                </ol>
                            </nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h1 style={{ fontSize: '48px', color: '#09f', marginBottom: '20px' }}>Контакты</h1>
                            <p style={{ fontSize: '20px' }}>
                                <strong>АО «Автобусный парк №1»</strong>
                            </p>
                            <p>
                                <strong>Адрес головного офиса:</strong> г. Астана, ул. Александра Пушкина, 37/1
                            </p>
                            <p>
                                <strong>Остановка:</strong> АО "Автобусный парк №1" автобусы - 11, 18, 30, 41
                            </p>
                            <p>
                                <strong>Телефон диспетчерского-центра:</strong> 54 16 45, +7 777 781 28 72
                            </p>
                            <p>
                                <strong>Часы работы:</strong> Пн. - Пт. с 8.30 до 18.00 часов (обеденный перерыв с 13:00 до 14:30 часов)
                            </p>
                            <p>
                                <strong>Электронная почта:</strong> <a href="mailto:info@1ap.kz" style={{ color: '#007bff', textDecoration: 'none' }}>info@avtopark1.kz</a>
                            </p>
                        </Col>
                        <Col md={6}>
                            <div id="map" style={{
                                width: '100%',
                                height: '600px',
                                border: '5px solid #FF0', // Рамка карты с цветом #FF0
                                borderRadius: '20px', // Скругленные углы
                                overflow: 'hidden' // Обрезка содержимого, чтобы сохранить круглые углы
                            }}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
