import React from 'react';
import styles from './BoardOfDirectors.module.css'; // Подключите стили, если они нужны

const BoardOfDirectors = () => {
    return (
        <div className={styles.boardContainer}>
            <h2 className={styles.heading}>Совет Директоров</h2>
            <h3 className={styles.subheading}>Состав совета директоров АО «Автобусный парк №1»</h3>
            <div className={styles.directors}>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Отебаев Ерсин Кенжебаевич</strong></p>
                    <p className={styles.position}>Председатель Совета директоров АО «Автобусный парк №1». Заместитель акима г. Астана</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Карагойшин Асхат Жиенбаевич</strong></p>
                    <p className={styles.position}>Член Совета директоров АО «Автобусный парк №1». Руководитель ГУ «Управление транспорта и развития дорожной инфраструктуры г. Астана»</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Диханбаев Ержан Айтханович</strong></p>
                    <p className={styles.position}>Член Совета директоров АО «Автобусный парк №1». Заместитель руководителя ГУ «Управление транспорта и развития дорожной инфраструктуры г. Астана»</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Қайратұлы Бауыржан</strong></p>
                    <p className={styles.position}>Член Совета директоров АО «Автобусный парк №1». Заместитель руководителя ГУ «Управление активов и государственных закупок г. Астана»</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Тұқпатов Мадияр Дастанович</strong></p>
                    <p className={styles.position}>Член Совета директоров АО «Автобусный парк №1». Председатель правления АО «Автобусный парк №1»</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Курмангалиев Нурлан Толеович</strong></p>
                    <p className={styles.position}>Независимый директор, член Совета директоров АО «Автобусный парк №1»</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Есин Нураш Зекенович</strong></p>
                    <p className={styles.position}>Независимый директор, член Совета директоров АО «Автобусный парк №1»</p>
                </div>
                <div className={styles.director}>
                    <p className={styles.name}><strong>Бәдіғұл Айқын Нүкешұлы</strong></p>
                    <p className={styles.position}>Корпоративный секретарь</p>
                </div>
            </div>
        </div>
    );
};

export default BoardOfDirectors;
