import React from 'react';
import LostItemInfo from '../../Components/LostItemInfo/LostAndFoundInfo'; // Импорт компонента LostItemInfo

const FoundItemsPage = () => {
  return (
    <>
      <LostItemInfo />
    </>
  );
};

export default FoundItemsPage;
