import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>КОМПАНИЯ</h4>
          <ul>
            <li><a href="/company?tab=activity">Деятельность</a></li>
            <li><a href="/company?tab=leadership">Руководство</a></li>
            <li><a href="/company?tab=partners">Партнеры</a></li>
            <li><a href="/company?tab=vacancies">Вакансии</a></li>
            <li><a href="/company?tab=best-employees">Лучшие сотрудники</a></li>
            <li><a href="/company?tab=compliance-officer">Комплаенс-офицер</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>ПАССАЖИРАМ</h4>
          <ul>
            <li><a href="#">Маршруты</a></li>
            <li><a href="#">Транспортные карты</a></li>
            <li><a href="#">Пункты пополнения транспортных карт</a></li>
            <li><a href="#">Правила перевозки пассажиров и багажа в столице</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>ИНФОРМАЦИЯ</h4>
          <ul>
            <li><a href="#">Контакты</a></li>
            <li><a href="#">Новости</a></li>
            <li><a href="#">Пункты пополнения</a></li>
            <li><a href="#">Схема движения маршрутов</a></li>
            <li><a href="#">Галерея</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>НАШИ ПРИЛОЖЕНИЯ</h4>
          <ul>
            <li>Мобильное приложение Transcard доступно для пользователей iOS и Android</li>
            <li>
              <a href="#"><img src="/path/to/appstore.png" alt="App Store" /></a>
              <a href="#"><img src="/path/to/googleplay.png" alt="Google Play" /></a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p style={{fontSize: '20px'}}>&copy; 2024 АО «Автобусный парк №1»</p>
        <div className="social-icons">
          <a href="#"><img src="https://w7.pngwing.com/pngs/832/748/png-transparent-computer-icons-facebook-inc-business-logo-facebook-logo-business-business-cards.png" alt="Facebook" /></a>
          <a href="#"><img src="https://cdn-icons-png.flaticon.com/512/748/748145.png" alt="Instagram" /></a>
          <a href="#"><img src="https://image.similarpng.com/very-thumbnail/2020/10/Tiktok-icon-logo-premium-vector-PNG.png" alt="TikTok" /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
