import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import InfoBlock from '../../Components/InfoBlock';
import LostAndFoundModal from '../../Components/LostAndFoundModal'; // Импортируем компонент модального окна
import infoData from '../../data/infoItems.json'; // Путь к вашему JSON-файлу
import icon1 from '../../icon/2751253.png';
import icon2 from '../../icon/2984172.png';
import icon3 from '../../icon/2154936.png';
import icon4 from '../../icon/916884.png';
import './ForPassengers.css'; // Подключаем файл стилей

export default class ForPassengers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedItem: null,
    };
  }

  handleShowModal = (index) => {
    this.setState({ showModal: true, selectedItem: index });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, selectedItem: null });
  };

  render() {
    const { showModal, selectedItem } = this.state;
    const icons = [icon1, icon2, icon3, icon4]; // Массив иконок по порядку

    return (
      <Container className="for-passengers-container">
        {infoData.infoItems.map((item, index) => (
          <InfoBlock
            key={index}
            title={item.title}
            description={item.description}
            icon={icons[index]} // Передаем иконки из массива
            className="custom-row" // Применение класса для дополнительной настройки
            onClick={() => this.handleShowModal(index)} // Обработка клика
          />
        ))}

        {/* Рендерим модальное окно только для первого элемента (Потерянные и найденные вещи) */}
        {selectedItem === 0 && (
          <LostAndFoundModal show={showModal} handleClose={this.handleCloseModal} />
        )}
      </Container>
    );
  }
}
