import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import './AdvertisingSection.css';  // Подключаем файл со стилями

const AdvertisingSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const handleShowModal = (ad) => {
    setSelectedAd(ad);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAd(null);
  };

  return (
    <section className="advertising-section py-5" aria-labelledby="advertising-heading">
      <Container>
        <Row>
          <Col>
            <h2 id="advertising-heading">Реклама на Автобусах</h2>
            <p>Используйте наши автобусы как мобильные рекламные площадки для вашего бизнеса.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src="https://sharaina.kz/thumb/2/4VjA4tksTsP9WkwsNUuKUA/r/d/avtobus.jpg" alt="Реклама на бортах автобусов" />
              <Card.Body>
                <Card.Title>Реклама на бортах</Card.Title>
                <Card.Text>Максимальная видимость на улицах города.</Card.Text>
                <Button variant="primary" onClick={() => handleShowModal('onBoard')}>Узнать больше</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src="https://optim.tildacdn.com/tild3231-3939-4138-b564-313435346465/-/resize/640x/-/format/webp/noroot.jpg" alt="Реклама внутри автобусов" />
              <Card.Body>
                <Card.Title>Реклама внутри автобуса</Card.Title>
                <Card.Text>Привлеките внимание пассажиров внутри транспортного средства.</Card.Text>
                <Button variant="primary" onClick={() => handleShowModal('inside')}>Узнать больше</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedAd === 'onBoard' ? 'Реклама на бортах автобусов' : 'Реклама внутри автобуса'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedAd === 'onBoard' ? (
              <>
                <p>Размещение рекламы на более 700 автобусах, Вас заметят более 500 тысяч пассажиров и более 350 тыс. водителей автомобилей.</p>
                <p>Реклама размещается по обеим сторонам автобуса на разных языках.</p>
              </>
            ) : (
              <>
                <p>Рекламные постеры над окнами в автобусах г. Астана. Размер каждой панели: от 152 см * 38 см до 200 см * 50 см.</p>
                <p>Реклама на видеомониторах в Автобусах Астаны. Мониторы форматом: 22 см х 40 см, диагональ - 46 см.</p>
                <p>Количество мониторов в каждом автобусе: по 2 монитора в 12-метровых автобусах и по 4 монитора в 18-метровых автобусах.</p>
              </>
            )}

            <hr />

            <p>
              Заинтересованы? Мы готовы обсудить детали и предложить оптимальные условия для вашего бизнеса.
              Пожалуйста, заполните форму ниже, и мы свяжемся с вами для дальнейшего сотрудничества.
            </p>

            <Form>
              <Form.Group controlId="companyName">
                <Form.Label>Наименование компании</Form.Label>
                <Form.Control type="text" placeholder="Введите название вашей компании" />
              </Form.Group>
              <Form.Group controlId="contactPerson">
                <Form.Label>Контактное лицо</Form.Label>
                <Form.Control type="text" placeholder="Введите ваше имя" />
              </Form.Group>
              <Form.Group controlId="contactNumber">
                <Form.Label>Контактный номер телефона</Form.Label>
                <Form.Control type="tel" placeholder="Введите ваш номер телефона" />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Введите ваш email" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Закрыть
            </Button>
            <Button variant="primary">
              Отправить заявку
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default AdvertisingSection;
