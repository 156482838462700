import React from 'react';
import { useParams } from 'react-router-dom';

const CompanyDetails = () => {
  const { companyId } = useParams();

  const companyInfo = {
    1: { name: 'Forte', description: 'Описание компании Forte...' },
    2: { name: 'CTS', description: 'Описание компании CTS...' },
    3: { name: 'EBRR', description: 'Описание компании EBRR...' },
    4: { name: 'FRP', description: 'Описание компании FRP...' },
    5: { name: 'Halyk Bank', description: 'Описание компании Halyk Bank...' },
    6: { name: 'Iveco', description: 'Описание компании Iveco...' },
    7: { name: 'Yutong', description: 'Описание компании Yutong...' },
    8: { name: 'Avtobys', description: 'Описание компании Avtobys...' },
    9: { name: 'Saryarka', description: 'Описание компании Saryarka...' },
    10: { name: 'QazTechna', description: 'Описание компании QazTechna...' },
  };

  const company = companyInfo[companyId];

  if (!company) {
    return <div>Информация о компании не найдена.</div>;
  }

  return (
    <div>
      <h2>{company.name}</h2>
      <p>{company.description}</p>
    </div>
  );
};

export default CompanyDetails;
